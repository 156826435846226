.outerContainer {
  outline: none;
  -webkit-tap-highlight-color: transparent;
  transition: filter 200ms ease;

  background-color: #fff;
}

.outerContainer.obscured {
  filter: brightness(0.5);
  user-select: none;
  cursor: default;
}

.outerContainer.obscured * {
  /* stop clicking of other things */
  pointer-events: none;
}
