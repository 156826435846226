.copyButton {
  background: var(--navbar-color);
  color: #fff;

  border: none;
  border-radius: 3px;

  padding: 6px 10px 6px 10px;

  font-size: 14px;

  cursor: pointer;
  user-select: none;
}