.tweets {
  /* border: 4px solid #d03352; */
  border: none;
  /* height: 100%; */
  left: 0;
  /* position: absolute; */
  /* top: 0; */
  width: 100%;
  height: calc(100vh - 55px); /* 55px = sum of menu/footer iframe height */
}
html,
body {
  height: 100%;
}
