:root {
  /* navbar */
  --navbar-color: #d03333;
  --navbar-gradient: linear-gradient(
    45deg,
    rgba(208, 51, 51, 1) 0%,
    rgb(240, 56, 89) 100%
  );
  --navbar-height: 65px;

  /* footer */
  --footer-height: 80px;

  /* default font */
  font-family: "Montserrat", sans-serif;

  /* app misc. */
  --app-padding: 15px;
}

html,
body {
  background-color: #fff;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #000;
}

a {
  color: var(--navbar-color);
}

.codeText {
  background: rgb(237, 237, 237);
  color: #444;
  border-radius: 3px;
  padding: 1px 3px 1px 3px;

  font-family: monospace;
  font-size: 16px;
}

.paddedContainer {
  padding: var(--app-padding);
}
