.tweetButton {
  display: block;
  width: 100%;

  color: #fff;
  background: var(--navbar-color);
  background: var(--navbar-gradient);

  text-align: center;
  text-decoration: none;

  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;

  margin: 0;
  padding: 18px 0px 18px 0px;

  border: none;
  border-radius: 5px;
}

/* .tweetEmbedContainer {
  width: 100%;
  position: relative;
  margin-top: 15px;
}

.tweetEmbed {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
} */
