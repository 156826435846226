.footer {
  width: 100%;
  height: var(--footer-height);

  font-family: "Montserrat", sans-serif;
  background-color: rgb(240, 240, 240);
  color: rgb(129, 129, 129);
  text-align: center;

  position: relative;
}

.footer .footerBody {
  width: 100%;
  position: absolute;

  padding: 0 15px 0 15px;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.footerLink {
  color: var(--navbar-color);
  font-weight: 700;
  text-decoration: none;
}

.footerLink:hover {
  text-decoration: underline;
}
