/* homepage header */
.header {
  margin-top: 0;
  margin-bottom: 10px;
  color: #000;
}

.subtitle {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: left;
  background: #ffffff;
  color: black;
  font-size: 24px;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  text-decoration: none;

  margin-top: 0;
}

.subtitleLink {
  color: #d03352;
  text-decoration: underline;
}

/* .subtitleLink:hover {
  text-decoration: underline;
} */

.tweets {
  overflow: hidden;
  /* 16:9 aspect ratio */
  padding-top: 56.25%;
  position: relative;
}

.tweets iframe {
  border: 4px solid #d03352;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.homeButton {
  display: block;
  width: 100%;

  color: #fff;
  background: var(--navbar-color);
  background: var(--navbar-gradient);

  text-align: center;
  text-decoration: none;
  user-select: none;

  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 700;

  margin: 0;
  padding: 18px 0px 18px 0px;

  border: none;
  border-radius: 5px;
}

.homeButton:not(:last-child) {
  margin-bottom: 15px;
}

.homeButton .discordLogo {
  width: 35px;
  margin-right: 0.42em;

  position: relative;
  top: 2px;

  display: inline-block;
}