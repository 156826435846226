.donatePageKoFiButton {
  margin-bottom: 10px;
}

.donateHeading {
  margin-top: 0;
}

.donateCryptoItem {
  margin-bottom: 5px;
}