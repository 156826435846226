nav.navBar {
  color: #fff;

  background: var(--navbar-color);
  background: var(--navbar-gradient);

  width: 100%;
  height: var(--navbar-height);
  display: block;

  position: fixed;
  top: 0;

  z-index: 2;
}

.logoArea {
  position: absolute;
  left: 10px;

  width: auto;
  height: 100%;
  display: inline-block;

  -webkit-user-select: none;
  user-select: none;
}

.logoArea img.logoImg {
  height: 65%;
  float: left;

  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.logoArea .logoText {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  line-height: var(--navbar-height);
  text-decoration: none;

  margin-left: 10px;

  /* position: absolute; */
  /* top: 0; */

  display: inline-block;
}

nav.navBar.navBarSmall .logoArea {
  left: 50%;
  transform: translateX(-50%);
}

nav.navBar.navBarSmall .logoArea .logoText {
  display: none;
}

.navButtons {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

nav.navBar.navBarSmall .navButtons {
  display: none;
}

.navButton {
  margin-right: 10px;
  padding: 10px;

  background-color: transparent;
  color: #fff;

  font-family: "Poppins", sans-serif;
  font-weight: 700;
  text-decoration: none;

  border-radius: 6px;
  border: 2px solid #fff;

  transition: background-color 150ms ease-out, color 150ms ease-out;
}

.navButton:hover,
.navButton.activeItem {
  color: var(--navbar-color);
  background-color: #fff;
}

.sideBarButton {
  width: var(--navbar-height);
  height: var(--navbar-height);

  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  cursor: pointer;

  border: none;
  -webkit-tap-highlight-color: transparent;

  background-color: transparent;
  color: #fff;

  font-size: 34px;
}

.sideBarButton .sideBarIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sideBarButton.hidden {
  display: none;
}
